// Custom styles
//
// This is the place where you should place all additional styles and imports.
// This will allow you to take advantage of future updates with lower time consumed on the upgrade process.

// *** TYPOGRAPHY ***

* {
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
}

*:after, *:before {
  font-family: unset;
  font-style: unset;
}

// Headings

h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 150%;
}

h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 150%;
}

h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
}

h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.005em;
}

h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}

h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
}

.custom-heading {
  font-weight: 600;
  font-size: 11px;
  line-height: 150%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

// Text

.font-thin {
  font-weight: 100;
}
.font-light {
  font-weight: 300;
}
.font-regular {
  font-weight: 400;
}
.font-semi-bold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-extra-bold {
  font-weight: 800;
}
.font-black {
  font-weight: 900;
}

.text-small {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

.text-caption {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

.text-surtitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 162.02%;
}

.text-xs {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.text-xs-sb {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.text-sm {
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
}

.text-sm-sb {
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
}

.text-lg {
  font-weight: 400;
  font-size: 20px;
  line-height: 136.02%;
}

.text-xl {
  font-weight: 400;
  font-size: 28px;
  line-height: 136.02%;
}

// Navbar

.text-nav-link {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.text-nav-link-lg {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

// *** SHADOW ***

.shadow {
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1), 0px 4px 20px -2px rgba(50, 50, 71, 0.08);
}

.shadow-large {
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1), 0px 10px 16px rgba(20, 37, 63, 0.06);
}

.shadow-extra-large {
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1), 0px 20px 24px rgba(20, 37, 63, 0.06);
}

.shadow-2-extra-large {
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1), 0px 30px 40px rgba(20, 37, 63, 0.08);
}

.shadow-inner {
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.06);
}

.shadow-small {
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
}

.shadow-outline {
  box-shadow: 0px 0px 0px 2px rgba(76, 111, 255, 0.3);
}

.shadow-input {
  box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
}

.shadow-input-success {
  box-shadow: 0px 1px 2px #66CB9F, 0px 0px 1px #66CB9F;
}

.shadow-table {
  box-shadow: inset 0px -1px 0px #EDF2F7;
}

// *** BUTTONS ***

// Button

@mixin text-btn {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 12px !important;
}

@mixin text-btn-sm {
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 10px !important;
  letter-spacing: -0.005em !important;
}

@mixin text-btn-lg {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 14px !important;
}

button {
  background-color: transparent;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-width: unset;
  height: fit-content;
  box-shadow: unset;
  margin: 0;

  // Typography, size and shape

  &.btn-icon {
    &.btn-rounded {
      padding: 10px;
      border-radius: 100px;

      i, .icon {
        width: 16px;
        height: 16px;
      }
  
      &.btn-sm {
        padding: 6px;
        border-radius: 100px;
  
        i, .icon {
          width: 14px;
          height: 14px;
        }
      }
    
      &.btn-lg {
        padding: 14px;
        border-radius: 100px;
  
        i, .icon {
          width: 18px;
          height: 18px;
        }
      }
    }

    &:not(.btn-rounded) {
      padding: 10px;
      border-radius: 10px;

      i, .icon {
        width: 16px;
        height: 16px;
      }
  
      &.btn-sm {
        padding: 6px;
        border-radius: 4px;
  
        i, .icon {
          width: 14px;
          height: 14px;
        }
      }
    
      &.btn-lg {
        padding: 14px;
        border-radius: 8px;
  
        i, .icon {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  &:not(.btn-icon) {
    &.btn-rounded {
      @include text-btn;
      padding: 12px 20px;
      border-radius: 100px;

      i, .icon {
        width: 12px;
        height: 12px;
      }
  
      &.btn-sm {
        @include text-btn-sm;
        padding: 8px 16px;
        border-radius: 100px;
  
        i, .icon {
          width: 10px;
          height: 10px;
        }
      }
    
      &.btn-lg {
        @include text-btn-lg;
        padding: 16px 24px;
        border-radius: 50px;
  
        i, .icon {
          width: 16px;
          height: 16px;
        }
      }
    }

    &:not(.btn-rounded) {
      @include text-btn;
      padding: 12px 16px;
      border-radius: 6px;

      i, .icon {
        width: 12px;
        height: 12px;
      }
  
      &.btn-sm {
        @include text-btn-sm;
        padding: 8px 16px;
        border-radius: 4px;
  
        i, .icon {
          width: 10px;
          height: 10px;
        }
      }
    
      &.btn-lg {
        @include text-btn-lg;
        padding: 16px;
        border-radius: 8px;
  
        i, .icon {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  // Type and color
  &.btn-solid,
  &.btn-solid:active {
    color: var(--white) !important;

    &.btn-main {
      color: var(--dark) !important;
      border: 1px solid var(--secondary) !important;
      background-color: var(--secondary) !important;
    }
    &.btn-primary-dark {
      border: 1px solid var(--primary-dark) !important;
      background-color: var(--primary-dark) !important;
    }
    &.btn-primary {
      border: 1px solid var(--primary) !important;
      background-color: var(--primary) !important;
    }
    &.btn-secondary {
      color: var(--dark-light) !important;
      border: 1px solid var(--secondary) !important;
      background-color: var(--secondary) !important;
    }
    &.btn-tertiary {
      border: 1px solid var(--tertiary) !important;
      background-color: var(--tertiary) !important;
    }
    &.btn-success {
      border: 1px solid var(--success) !important;
      background-color: var(--success) !important;
    }
    &.btn-warning {
      border: 1px solid var(--warning) !important;
      background-color: var(--warning) !important;
    }
    &.btn-danger {
      border: 1px solid var(--danger) !important;
      background-color: var(--danger) !important;
    }
    &.btn-info {
      border: 1px solid var(--info) !important;
      background-color: var(--info) !important;
    }
    &.btn-white {
      color: var(--dark) !important;
      border: 1px solid var(--white) !important;
      background-color: var(--white) !important;
      @extend .shadow-small;
    }
  }

  &.btn-soft,
  &.btn-soft:active {
    &.btn-main {
      color: var(--dark) !important;
      border: 1px solid var(--secondary-light) !important;
      background-color: var(--secondary-light) !important;
    }
    &.btn-primary {
      color: var(--primary) !important;
      border: 1px solid var(--primary-soft) !important;
      background-color: var(--primary-soft) !important;
    }
    &.btn-secondary {
      color: var(--secondary-dark) !important;
      border: 1px solid var(--secondary-light) !important;
      background-color: var(--secondary-light) !important;
    }
    &.btn-tertiary {
      color: var(--tertiary) !important;
      border: 1px solid var(--tertiary-soft) !important;
      background-color: var(--tertiary-soft) !important;
    }
    &.btn-success {
      color: var(--success) !important;
      border: 1px solid var(--success-soft) !important;
      background-color: var(--success-soft) !important;
    }
    &.btn-warning {
      color: var(--warning) !important;
      border: 1px solid var(--warning-soft) !important;
      background-color: var(--warning-soft) !important;
    }
    &.btn-danger {
      color: var(--danger) !important;
      border: 1px solid var(--danger-soft) !important;
      background-color: var(--danger-soft) !important;
    }
    &.btn-info {
      color: var(--info) !important;
      border: 1px solid var(--info-soft) !important;
      background-color: var(--info-soft) !important;
    }
    &.btn-white {
      color: var(--dark) !important;
      border: 1px solid var(--white) !important;
      background-color: var(--white) !important;
    }
  }

  &.btn-outline,
  &.btn-outline:active {
    background-color: var(--white-translucent) !important;

    &.btn-main {
      color: var(--dark) !important;
      border: 1px solid var(--secondary) !important;
    }
    &.btn-primary-dark {
      color: var(--primary-dark) !important;
      border: 1px solid var(--primary-dark) !important;
    }
    &.btn-primary {
      color: var(--primary) !important;
      border: 1px solid var(--primary) !important;
    }
    &.btn-secondary {
      color: var(--secondary-dark) !important;
      border: 1px solid var(--secondary) !important;
    }
    &.btn-tertiary {
      color: var(--tertiary) !important;
      border: 1px solid var(--tertiary) !important;
    }
    &.btn-success {
      color: var(--success) !important;
      border: 1px solid var(--success) !important;
    }
    &.btn-warning {
      color: var(--warning) !important;
      border: 1px solid var(--warning) !important;
    }
    &.btn-danger {
      color: var(--danger) !important;
      border: 1px solid var(--danger) !important;
    }
    &.btn-info {
      color: var(--info) !important;
      border: 1px solid var(--info) !important;
    }
    &.btn-white {
      color: var(--dark) !important;
      border: 1px solid var(--gray-200) !important;
    }
  }

  &.btn-dashed,
  &.btn-dashed:active {
    background-color: var(--white-translucent) !important;

    &.btn-main {
      color: var(--dark) !important;
      border: 1px dashed var(--secondary) !important;
    }
    &.btn-primary-dark {
      color: var(--primary-dark) !important;
      border: 1px dashed var(--primary-dark) !important;
    }
    &.btn-primary {
      color: var(--primary) !important;
      border: 1px dashed var(--primary) !important;
    }
    &.btn-secondary {
      color: var(--secondary-dark) !important;
      border: 1px dashed var(--secondary) !important;
    }
    &.btn-tertiary {
      color: var(--tertiary) !important;
      border: 1px dashed var(--tertiary) !important;
    }
    &.btn-success {
      color: var(--success) !important;
      border: 1px dashed var(--success) !important;
    }
    &.btn-warning {
      color: var(--warning) !important;
      border: 1px dashed var(--warning) !important;
    }
    &.btn-danger {
      color: var(--danger) !important;
      border: 1px dashed var(--danger) !important;
    }
    &.btn-info {
      color: var(--info) !important;
      border: 1px dashed var(--info) !important;
    }
    &.btn-white {
      color: var(--dark) !important;
      border: 1px dashed var(--gray-200) !important;
    }
  }
}
