@font-face {
  font-family: "Proxima Nova";
  font-weight: 900;
  src: url("../assets/fonts/ProximaNova-Black.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 800;
  src: url("../assets/fonts/ProximaNova-Xbold.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 700;
  src: url("../assets/fonts/ProximaNova-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 600;
  src: url("../assets/fonts/ProximaNova-Sbold.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 400;
  src: url("../assets/fonts/ProximaNova-Reg.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 300;
  src: url("../assets/fonts/ProximaNova-Light.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 100;
  src: url("../assets/fonts/ProximaNova-Thin.otf") format("opentype");
}
