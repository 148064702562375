:root {
  --primary: #0D7FA9;
  --primary-light: #57AEDB;
  --primary-dark: #00537A;
  --primary-gradient: linear-gradient(225deg, #57AEDB 0%, #4C6FFF 100%, #00537A 100%);
  --primary-soft: #DCF6FF;

  --secondary: #E4ECF7;
  --secondary-light: #EBF2FA;
  --secondary-dark: #A6B7D4;
  --secondary-gradient: linear-gradient(225deg, #F1F1F5 0%, #E4ECF7 100%);

  --tertiary: #3569DB;
  --tertiary-light: #7496FF;
  --tertiary-dark: #003FA9;
  --tertiary-gradient: linear-gradient(224.47deg, #7496FF 8.18%, #003FA9 95.84%);
  --tertiary-soft: #E3EAFF;

  --success: #00A75F;
  --success-light: #54DA8D;
  --success-dark: #007734;
  --success-gradient: linear-gradient(225deg, #54DA8D 0%, #007734 100%);
  --success-soft: #DEFFEE;

  --warning: #E2734A;
  --warning-light: #FFA377;
  --warning-dark: #AB4520;
  --warning-gradient: linear-gradient(225deg, #FFA377 0%, #AB4520 100%);
  --warning-soft: #FFEDE3;

  --danger: #C94547;
  --danger-light: #FF7672;
  --danger-dark: #920820;
  --danger-gradient: linear-gradient(225deg, #FF7672 0%, #920820 100%);
  --danger-soft: #FFE6E4;

  --info: #1C92BD;
  --info-light: #61C2F0;
  --info-dark: #00648D;
  --info-gradient: linear-gradient(225deg, #61C2F0 0.01%, #00648D 100%);
  --info-soft: #E6FDFF;

  --dark: #16192C;
  --dark-light: #505780;
  --dark-dark: #101225;
  --dark-gradient: linear-gradient(225deg, #29272E 0%, #27272E 100%);
  --dark-translucent: rgba(16, 16, 18, 0.4);

  --white: #FFFFFF;
  --white-translucent: transparent;

  --gray-50: #FAFAFA;
  --gray-100: #F7FAFC;
  --gray-200: #EDF2F7;
  --gray-300: #E2E8F0;
  --gray-400: #CBD5E0;
  --gray-500: #A0AEC0;
  --gray-600: #718096;
  --gray-700: #4A5568;
  --gray-800: #2D3748;
  --gray-900: #1A202C;

  --text-body-light: #425466;
  --text-body-dark: #F2F2F2;
  --text-body-muted: #718096;

  --text-heading-dark: #27272E;
  --text-heading-light: #FFFFFF;

  --surface-primary: #FFFFFF;
  --surface-secondary: #F7FAFC;
  --surface-light: #D5D5DC;
  --surface-dark: #17171B;

  --border-light: #EDF2F7;
  --border-dark: #16192C;

  --input-border: #E7E7E7;
  --input-background: #FFFFFF;
  --input-placeholder: #B5B5BD;
  --input-text: #494949;

  --input-active-border: #FFFFFF;
  --input-active-background: #FFFFFF;
  --input-active-placeholder: #B5B5BD;

  --input-muted-background: #EDF2F7;
  --input-muted-placeholder: #7A828A;

  --table-head-text: #8492A6;
  --table-head-background: #FAFAFB;
  --table-body-background: #FFFFFF;
  --table-body-strong: #425466;
}
